import { zonesService } from "@/api_services/zones.service";
import Vue from "vue";

let initialState = {
  zonesList: [],
  loading: false,
};

const state = Vue.util.extend({}, initialState);

export default {
  namespaced: true,
  state: state,
  getters: {},
  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },
    setZonesList(state, list) {
      state.zonesList = list;
    },
    resetState(state) {
      for (let f in state) {
        Vue.set(state, f, initialState[f]);
      }
    },
  },
  actions: {
    GET_ZONES: (context) => {
      return new Promise((resolve) => {
        context.commit("setLoading", true);
        zonesService
          .getAll()
          .then(({ data }) => {
            context.commit("setZonesList", data);
            context.commit("setLoading", false);
            resolve(data);
          })
          .catch((e) => {
            context.commit("setLoading", false);
            console.error(e);
          });
      });
    },

    GET_PUBLIC_ZONES: (context, locode) => {
      return new Promise((resolve) => {
        context.commit("setLoading", true);
        zonesService
          .getAllPublic(locode)
          .then(({ data }) => {
            context.commit("setZonesList", data);
            context.commit("setLoading", false);
            resolve(data);
          })
          .catch((e) => {
            context.commit("setLoading", false);
            console.error(e);
          });
      });
    },

    RESET: (context) => {
      context.commit("resetState");
    },

    IMPORT_ZONES: (context, payload) => {
      return new Promise((resolve, reject) => {
        zonesService
          .importZones(payload)
          .then(({ data }) => {
            resolve(data);
            context.dispatch("GET_ZONES");
          })
          .catch((e) => {
            context.dispatch("GET_ZONES");
            console.error(e);
            reject(e);
          });
      });
    },

    EXPORT: (_, locode) => {
      return new Promise((resolve, reject) => {
        zonesService
          .exportZones()
          .then((res) => {
            var reader = new FileReader();
            reader.onload = function (event) {
              var fileData = event.target.result;
              var fileBlob = new Blob([fileData], { type: "application/json" });
              var fileURL = window.URL.createObjectURL(fileBlob);
              var fileLink = document.createElement("a");

              fileLink.href = fileURL;
              fileLink.setAttribute(
                "download",
                "terminais_" + locode.code + ".geojson"
              );
              document.body.appendChild(fileLink);

              fileLink.click();

              resolve(res.data);
            };
            reader.readAsText(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    UPDATE_ZONE: (context, zone) => {
      return new Promise((resolve, reject) => {
        zonesService
          .updateZone(zone)
          .then((res) => {
            resolve(res.data);
            context.dispatch("GET_ZONES");
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    DELETE_ZONE: (context, id) => {
      return new Promise((resolve, reject) => {
        zonesService
          .deleteZone(id)
          .then((res) => {
            resolve(res.data);
            context.dispatch("GET_ZONES");
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
  },
};
