import { shipsService } from "@/api_services/ships.service";

export default {
  namespaced: true,
  state: {
    list: [],
    totalNumberOfShips: 0,
    searchedTerm: "",
    currentPage: 1,
    itemsPerPage: 10,
    sortBy: "name",
    sortDesc: false,
    countriesList: [],
    payload: {
      search: "",
      currentPage: 1,
      itemsPerPage: 10,
      sortBy: "name",
      sortDesc: true,
    },
  },
  getters: {},
  mutations: {
    setShips(state, ships) {
      state.list = ships;
    },
    setTotalNumberOfShips(state, total) {
      state.totalNumberOfShips = total;
    },
    setItemsPerPage(state, itemsPerPage) {
      state.itemsPerPage = itemsPerPage;
    },
    setCurrentPage(state, currentPage) {
      state.currentPage = currentPage;
    },
    setSearchedTerm(state, searchedTerm) {
      state.searchedTerm = searchedTerm;
    },
    setSortBy(state, sortBy) {
      state.sortBy = sortBy;
    },
    setSortDesc(state, sortDesc) {
      state.sortDesc = sortDesc;
    },
    setCountriesList(state, countriesList) {
      state.countriesList = countriesList;
    },
    setPayload(state, payload) {
      state.payload = payload;
    },
    setDefault(state) {
      (state.searchedTerm = ""),
        (state.currentPage = 1),
        (state.itemsPerPage = 10),
        (state.sortBy = "description"),
        (state.sortDesc = false),
        (state.countriesList = []),
        (state.payload = {
          search: "",
          currentPage: 1,
          itemsPerPage: 10,
          sortBy: "description",
          sortDesc: true,
        });
    },
  },
  actions: {
    RESET(context) {
      return new Promise((resolve) => {
        context.commit("setDefault");
        resolve();
      });
    },
    SET_ITEMS_PER_PAGE(context, itemsPerPage) {
      return new Promise((resolve) => {
        context.commit("setItemsPerPage", itemsPerPage);
        resolve();
      });
    },
    SET_CURRENT_PAGE(context, currentPage) {
      return new Promise((resolve) => {
        context.commit("setCurrentPage", currentPage);
        resolve();
      });
    },
    SET_SEARCHED_TERM(context, searchedTerm) {
      return new Promise((resolve) => {
        context.commit("setSearchedTerm", searchedTerm);
        resolve();
      });
    },
    SET_SORT_BY(context, sortBy) {
      return new Promise((resolve) => {
        context.commit("setSortBy", sortBy);
        resolve();
      });
    },
    SET_SORT_DESC(context, sortDesc) {
      return new Promise((resolve) => {
        context.commit("setSortDesc", sortDesc);
        resolve();
      });
    },
    SET_TOTAL_NUMBER_OF_SHIPS(context, total) {
      return new Promise((resolve) => {
        context.commit("setTotalNumberOfShips", total);
        resolve();
      });
    },
    SET_SHIPS(context, records) {
      return new Promise((resolve) => {
        context.commit("setShips", records);
        resolve();
      });
    },

    SET_PAYLOAD(context, payload) {
      return new Promise((resolve) => {
        context.commit("setPayload", payload);
        resolve();
      });
    },

    GET_SHIPS: (context) => {
      return new Promise((resolve, reject) => {
        shipsService
          .getShips(context.state.payload)
          .then((res) => {
            context.commit("setShips", res.data.data);
            context.commit("setTotalNumberOfShips", res.data.total);
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    GET_COMPLETE_LIST_OF_SHIPS: (context, search) => {
      return new Promise((resolve, reject) => {
        shipsService
          .getCompleteListOfShips(search)
          .then((res) => {
            context.commit("setShips", res.data);
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    GET_SHIP_BY_ID: (_, shipId) => {
      return new Promise((resolve, reject) => {
        shipsService
          .getShipById(shipId)
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    GET_COUNTRIES_LIST: (context) => {
      return new Promise((resolve, reject) => {
        shipsService
          .getCountriesList()
          .then((res) => {
            context.commit("setCountriesList", res.data);
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    CREATE_SHIP: (context, ship) => {
      return new Promise((resolve, reject) => {
        shipsService
          .createShip(ship)
          .then((res) => {
            resolve(res.data);
            context.dispatch("GET_SHIPS");
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    UPDATE_SHIP: (context, ship) => {
      return new Promise((resolve, reject) => {
        shipsService
          .updateShip(ship)
          .then((res) => {
            resolve(res.data);
            context.dispatch("GET_SHIPS");
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
  },
};
