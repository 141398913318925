import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import Vuex from "vuex";
import VueRouter from "vue-router";
import router from "./router";
import store from "./store";
import axios from "axios";
import VCalendar from "v-calendar";
import VTooltip from "v-tooltip";
import CountryFlag from 'vue-country-flag'

// Constants
import Constants from "@/plugins/constants";

//Permission checker
import Permissions from "@/plugins/permissions";

import i18n from "@/locale";

// Filters
import "@/filters";

/*** fix checkbox in datatables: https://github.com/vuetifyjs/vuetify/issues/12224 */
import Ripple from "vuetify/lib/directives/ripple";

import { Plugin } from 'vue-fragment';

Vue.use(Vuex);
Vue.use(VueRouter);
Vue.use(Constants);
Vue.use(Permissions);
Vue.use(VCalendar, {
  componentPrefix: "vc", // Use <vc-calendar /> instead of <v-calendar />
});
Vue.use(VTooltip);
Vue.component('country-flag', CountryFlag);
Vue.use(Plugin);

new Vue({
  vuetify,
  router,
  store,
  axios,
  i18n,
  render: (h) => h(App),
  directives: {
    Ripple,
  },
}).$mount("#app");
