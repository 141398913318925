import { surveyService } from "@/api_services/surveys.service";
import Vue from "vue";

let initialState = {
  cells: null,
  uploading: false,
  surveyDates: [],
};

const state = Vue.util.extend({}, initialState);

export default {
  namespaced: true,
  state: state,
  getters: {},
  mutations: {
    setSurveyDates(state, dates) {
      state.surveyDates = dates || [];
    },
    setCells(state, cells) {
      state.cells = cells || [];
    },
    addCells(state, cells) {
      if (cells) state.cells.push(...cells);
    },
    setUploadingState(state, uploading) {
      state.uploading = uploading;
    },
    resetState(state) {
      for (let f in state) {
        Vue.set(state, f, initialState[f]);
      }
    },
  },
  actions: {
    GET_CELLS: (context, replace = false) => {
      return new Promise((resolve, reject) => {
        context.commit("setCells", null);
        surveyService
          .getCells()
          .then(({ data }) => {
            if (data.length > 0) {
              if (replace) {
                context.commit(
                  "setCells",
                  data.map((survey) => survey.cells).flat()
                );
              } else {
                context.commit(
                  "addCells",
                  data.map((survey) => survey.cells).flat()
                );
              }

              context.commit(
                "setSurveyDates",
                data.sort().map((survey) => survey.date)
              );

              context.commit(
                "setUploadingState",
                data.some((survey) => survey.uploading)
              );
            }
            resolve(data);
          })
          .catch((e) => {
            context.commit("setCells", null); //set cells
            context.commit("setUploadingState", false);
            console.error(e);
            reject(e);
          });
      });
    },

    RESET: (context) => {
      context.commit("resetState");
    },

    IMPORT_POINTS: (context, payload) => {
      context.dispatch("RESET");

      return new Promise((resolve, reject) => {
        surveyService
          .importPoints(payload)
          .then(({ data }) => {
            resolve(data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    GET_ZH: (_, mooring_bitts) => {
      return new Promise((resolve) => {
        surveyService
          .getZH(mooring_bitts.end_mooring_stern, mooring_bitts.end_mooring_bow)
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
          });
      });
    },
  },
};
