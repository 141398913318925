import {
  layersService
} from "@/api_services/layers.service";
import Vue from "vue";

let initialState = {
  layersList: [],
};

const state = Vue.util.extend({}, initialState);

export default {
  namespaced: true,
  state: state,
  getters: {},
  mutations: {
    setLayers(state, layers) {
      state.layersList = layers;
    },

    resetState(state) {
      for (let f in state) {
        Vue.set(state, f, initialState[f]);
      }
    },
  },
  actions: {
    GET_LAYERS: (context) => {
      return new Promise((resolve, reject) => {
        layersService
          .getLayers()
          .then((res) => {
            context.commit("setLayers", res.data);
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    CREATE_LAYER: (context, layer) => {
      return new Promise((resolve, reject) => {
        layersService
          .createLayer(layer)
          .then((res) => {
            resolve(res.data);
            context.dispatch("GET_LAYERS");
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    UPDATE_LAYER: (context, layer) => {
      return new Promise((resolve, reject) => {
        layersService
          .updateLayer(layer)
          .then((res) => {
            resolve(res.data);
            context.dispatch("GET_LAYERS");
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    DELETE_LAYER: (context, layerId) => {
      return new Promise((resolve, reject) => {
        layersService
          .deleteLayer(layerId)
          .then((res) => {
            resolve(res.data);
            context.dispatch("GET_LAYERS");
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
    
    RESET: (context) => {
      context.commit("resetState");
    },
  },
};