import Vue from "vue";
import VueRouter from "vue-router";
import { sharedHelpers } from "@/api_services/utils/shared-helpers";
import store from "@/store/index";

import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style
NProgress.configure({}); // NProgress Configuration

Vue.use(VueRouter);

const router = new VueRouter({
  routes: [
    {
      path: "/",
      redirect: "/map",
    },
    {
      path: "/login",
      name: "LOGIN",
      component: () => import("@/pages/Auth/LoginPage"),
      meta: {
        noCache: true,
      },
    },
    {
      path: "/forgot-password",
      name: "FORGOTPASSWORD",
      component: () => import("@/pages/Auth/ForgotPasswordPage"),
      meta: {
        noCache: true,
      },
    },
    {
      path: "/reset-password",
      name: "RESETPASSWORD",
      component: () => import("@/pages/Auth/ResetPasswordPage"),
      meta: {
        noCache: true,
      },
    },
    {
      path: "/",
      component: () => import("@/pages/BaseLayout"),
      children: [
        {
          path: "/map",
          name: "MAP",
          component: () => import("@/pages/Main"),
          meta: {
            noCache: true,
          },
        },
        {
          path: "/mooring_bitts",
          name: "MooringBitts",
          component: () => import("@/pages/MooringBittsPage"),
          meta: {
            noCache: true,
          },
        },
        {
          path: "/zones",
          name: "Zones",
          component: () => import("@/pages/ZonesPage"),
          meta: {
            noCache: true,
          },
        },
        {
          path: "/layers",
          name: "Layers",
          component: () => import("@/pages/LayersPage"),
          meta: {
            noCache: true,
          },
        },
        {
          path: "/audit_logs",
          name: "AUDIT_LOGS",
          component: () => import("@/pages/AuditLogsPage"),
          meta: {
            noCache: true,
          },
        },
        {
          path: "/users",
          name: "Users",
          component: () => import("@/pages/UsersPage"),
          meta: {
            noCache: true,
          },
        },
        {
          path: "/ship_types",
          name: "ShipTypes",
          component: () => import("@/pages/ShipTypesPage"),
          meta: {
            noCache: true,
          },
        },
        {
          path: "/bathymetry",
          name: "Bathymetry",
          component: () => import("@/pages/BathymetryPage"),
          meta: {
            noCache: true,
          },
        },
        {
          path: "/tides",
          name: "Tides",
          component: () => import("@/pages/TidesPage"),
          meta: {
            noCache: true,
          },
        },
        {
          path: "/ships",
          name: "Ships",
          component: () => import("@/pages/ShipsPage"),
          meta: {
            noCache: true,
          },
        },
      ],
    },
    {
      path: "/",
      component: () => import("@/pages/PublicBaseLayout"),
      children: [
        {
          path: "/snapshots/:id",
          name: "ScreenshotSharePage",
          component: () => import("@/pages/ScreenshotSharePage"),
          props: true,
          meta: {
            noCache: true,
          },
        },
      ],
    },
    {
      path: "/snapshot_denied",
      component: () => import("@/pages/SnapshotDenied"),
    },
    { path: "/access_denied", component: () => import("@/pages/AccessDenied") },
    { path: "*", component: () => import("@/pages/NotFound") },
  ],
});

router.beforeEach(async (to, from, next) => {
  NProgress.start();
  const privatePages = [
    "/mooring_bitts",
    "/zones",
    "/hydro",
    "/layers",
    "/ship_types",
    "/users",
    "/ships",
  ];

  const publicPages = [
    "/login",
    "/forgot-password",
    "/reset-password",
    "/access_denied",
    "/snapshot_denied",
    "/snapshots",
  ];

  const authRequired = !publicPages.some((page) => to.path.startsWith(page));
  const account = sharedHelpers.getStorageItemWithExpiry("account");
  const locode = sharedHelpers.getStorageItemWithExpiry("locode");

  if (authRequired && (!account || !locode)) {
    return next({ path: "/login", replace: true });
  }

  store.dispatch("SET_ACCOUNT", account);
  store.dispatch("SET_LOCODE", locode);

  if (!privatePages.includes(to.path)) {
    next();
  } else {
    let userPermissions = sharedHelpers.getUserPermissions();

    let hasPermissions = userPermissions.find((permission) => {
      return permission.code.toLowerCase() === to.path.split("/")[1];
    });

    if (hasPermissions) {
      next();
    } else {
      next("/access_denied");
    }
  }
});

router.afterEach(async () => {
  NProgress.done();
});

export default router;
