import Vue from "vue";

let initialState = {
  all: [],
};

const state = Vue.util.extend({}, initialState);

export default {
  namespaced: true,
  state: state,
  mutations: {
    resetState(state) {
      for (let f in state) {
        Vue.set(state, f, initialState[f]);
      }
    },

    updateNotifications(state, recordActions) {
      let notifications = JSON.parse(JSON.stringify(state.all));
      recordActions.forEach((recordAction) => {
        if (
          recordAction.operation == "add" ||
          recordAction.operation == "update"
        ) {
          let item = recordAction.record;
          let foundIndex = state.all.findIndex((el) => {
            return el.id == item.id;
          });
          if (foundIndex !== -1) {
            notifications.splice(foundIndex, 1, item);
          } else {
            notifications.push(item);
          }
        } else if (recordAction.operation == "remove") {
          let item = recordAction.record;
          let foundIndex = notifications.findIndex((el) => {
            return el.id == item.id;
          });
          if (foundIndex !== null || typeof foundIndex == "undefined") {
            notifications.splice(foundIndex, 1);
          }
        }
      });
      state.all = notifications;
    },
  },

  actions: {
    UPDATE_NOTIFICATIONS(context, recordAction) {
      return new Promise((resolve) => {
        context.commit("updateNotifications", recordAction);
        resolve();
      });
    },

    RESET: (context) => {
      context.commit("resetState");
    },
  },
};
