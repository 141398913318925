import configs from "@/helpers/configs";
import axios from "axios";
import { sharedHelpers } from "@/api_services/utils/shared-helpers";

export const screenshotsService = {
  createScreenshot,
  updateScreenshot,
  getScreenshotByCode,
};

async function createScreenshot(screenshot) {
  let locode = await sharedHelpers.getLocode();
  screenshot.port_locode = locode.code;
  var requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.post(
    configs.getApiUrl() + "/v1/screenshots",
    screenshot,
    requestOptions
  );
}

async function updateScreenshot(screenshot) {
  var requestOptions = {
    method: "PUT",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.put(
    configs.getApiUrl() + "/v1/screenshots/" + screenshot.id,
    screenshot,
    requestOptions
  );
}

async function getScreenshotByCode(code) {
  var requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.get(
    configs.getApiUrl() + `/v1/public/${code}`,
    requestOptions
  );
}