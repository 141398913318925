import Vue from "vue";
import { Settings, DateTime } from "luxon";
import config from "@/config";
import configs from "@/helpers/configs";
Settings.defaultZone = configs.getTimezone();
Settings.defaultLocale = configs.getLocale();

// ========================== [ CUSTOM FILTERS] ==========================

Vue.filter("formatDatetimeFromDatabase", (value) => {
  return value
    ? DateTime.fromFormat(value, "yyyy-LL-dd HH:mm:ss").toFormat("dd/LL/yyyy HH:mm"): "";
});

Vue.filter("formatDatetime", (value) => {
  return value ? DateTime.fromISO(value).toFormat("dd/LL/yyyy HH:mm") : "";
});

Vue.filter("formatFullDatetime", (value) => {
  return value ? DateTime.fromISO(value).toFormat("dd/LL/yyyy HH:mm:ss") : "";
});

Vue.filter("formatDate", (value) => {
  return value ? DateTime.fromISO(value).toFormat("dd/LL/yyyy") : "";
});

Vue.filter("formatJSFullDatetime", (value) => {
  return value ? DateTime.fromJSDate(value).toFormat("dd/LL/yyyy HH:mm:ss") : "";
});

Vue.filter("formatJSDate", (value) => {
  return value ? DateTime.fromJSDate(value).toFormat("dd/LL/yyyy") : "";
});

Vue.filter("formatNumber", (value) => {
  if (typeof Intl !== "undefined" && value) {
    value = Number(value);
    return Intl.NumberFormat(config.app_locale, {
      maximumFractionDigits: 2,
    }).format(value);
  }
  //return value as is
  return value;
});

Vue.filter("formatTrueHeading", (value) => {
  if (typeof Intl !== "undefined" && value) {
    value = Number(value);
    return Intl.NumberFormat(config.app_locale, {
      maximumFractionDigits: 2,
    }).format(value);
  }
  //return value as is
  return value;
});

Vue.filter("formatAge", (value) => {
  if (value) {
    return value.replace("year", "ano").replace("mons", "meses").replace("mon", "mês").replace("day", "dia");
  }
  //return value as is
  return value;
});

// ========================== [/CUSTOM FILTERS] ==========================
