import configs from "@/helpers/configs";
import axios from "axios";
import { sharedHelpers } from "@/api_services/utils/shared-helpers";

export const plansService = {
  getPlans,
  getPlan,
  createPlan,
  updatePlan,
  deletePlan,
  archivePlan,
  validatePlan,
  exportPlan,
  getVersions,
  getVersion,
  deleteVersion,
};

async function getPlans(options) {
  let sort = options.sortDesc ? "DESC" : "ASC";
  var requestOptions = {
    method: "GET",
    params: {
      plansPerPage: options.itemsPerPage,
      currentPage: options.currentPage,
      sortBy: options.sortBy,
      sort: sort,
      search: options.search,
    },
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.get(configs.getApiUrl() + "/v1/plans", requestOptions);
}

async function getPlan(id) {
  var requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.get(
    configs.getApiUrl() + `/v1/plans/${id}`,
    requestOptions
  );
}

async function getVersion(id) {
  var requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.get(
    configs.getApiUrl() + `/v1/versions/${id}`,
    requestOptions
  );
}

async function getVersions(id) {
  var requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.get(
    configs.getApiUrl() + `/v1/plans/${id}/versions`,
    requestOptions
  );
}

async function createPlan(plan) {
  let locode = await sharedHelpers.getLocode();
  plan.port_locode = locode.code;
  var requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.post(
    configs.getApiUrl() + "/v1/plans",
    plan,
    requestOptions
  );
}

async function updatePlan(plan) {
  let locode = await sharedHelpers.getLocode();
  plan.port_locode = locode.code;
  var requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.put(
    configs.getApiUrl() + "/v1/plans/" + plan.id,
    plan,
    requestOptions
  );
}

async function archivePlan(plan) {
  let locode = await sharedHelpers.getLocode();
  plan.port_locode = locode.code;
  var requestOptions = {
    method: "PUT",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.put(
    configs.getApiUrl() + "/v1/plans/archive/" + plan.id,
    plan,
    requestOptions
  );
}

async function deletePlan(planId) {
  var requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.delete(
    configs.getApiUrl() + "/v1/plans/" + planId,
    requestOptions
  );
}

async function deleteVersion(planId, versionId) {
  var requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.delete(
    configs.getApiUrl() + "/v1/plans/" + planId + "/version/" + versionId,
    requestOptions
  );
}

async function validatePlan(plan) {
  let locode = await sharedHelpers.getLocode();
  plan.port_locode = locode.code;
  var requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.post(
    configs.getApiUrl() + "/v1/plans/validate",
    plan,
    requestOptions
  );
}

async function exportPlan(planId, versionId) {
  var requestOptions = {
    method: "POST",
    responseType: "blob",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.post(
    configs.getApiUrl() +
      "/v1/plans/export/" +
      planId +
      "/version/" +
      versionId,
    {},
    requestOptions
  );
}

