import configs from "@/helpers/configs";
import axios from "axios";
import { sharedHelpers } from "@/api_services/utils/shared-helpers";

export const surveyService = {
  getCells,
  importPoints,
  getZH
};

async function getCells() {
  var requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
  };
  const res = await axios.get(
    configs.getApiUrl() + "/v1/surveys",
    requestOptions
  );
  return res;
}

async function importPoints(payload) {
  let locode = await sharedHelpers.getLocode();
  var requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };
  var bodyFormData = new FormData();
  bodyFormData.append("file", payload.file);
  bodyFormData.append("replace", payload.replace);
  bodyFormData.append("date", payload.date);
  bodyFormData.append("locode", locode.code);
  const res = await axios.post(
    configs.getApiUrl() + "/v1/surveys/points",
    bodyFormData,
    requestOptions
  );
  return res;
}

async function getZH(end_mooring_stern, end_mooring_bow) {
  var requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };

  const res = await axios.post(
    configs.getApiUrl() + "/v1/surveys/zh",
    { end_mooring_stern: end_mooring_stern, end_mooring_bow: end_mooring_bow },
    requestOptions
  );
  return res;
}