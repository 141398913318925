import { settingsService } from "@/api_services/settings.service";
import Vue from "vue";

let initialState = {
  aisEnabled: true,
  timelineOpen: false,
  locale: "pt",
};

const state = Vue.util.extend({}, initialState);

export default {
  namespaced: true,
  state: state,
  mutations: {
    resetState(state) {
      for (let f in state) {
        Vue.set(state, f, initialState[f]);
      }
    },

    setAISEnabled(state, enabled) {
      state.aisEnabled = enabled;
    },
    setTimelineOpen(state, open) {
      state.timelineOpen = open;
    },
    setLocale(state, payload) {
      state.locale = payload.locale;
    },
  },
  actions: {
    SET_TIMELINE_OPEN: (context, open) => {
      context.commit("setTimelineOpen", open);
    },

    ENABLE_AIS: (context) => {
      context.commit("setAISEnabled", true);
    },
    DISABLE_AIS: (context) => {
      context.commit("setAISEnabled", false);
    },
    SET_AIS_ENABLED: (context, enabled) => {
      context.commit("setAISEnabled", enabled);
    },

    GET_LDAP_CONFIGURATIONS: () => {
      return new Promise((resolve, reject) => {
        settingsService
          .getLdapConfigurations()
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    UPDATE_LDAP_CONFIGURATIONS: (context, configs) => {
      return new Promise((resolve, reject) => {
        settingsService
          .saveLdapConfigurations(configs)
          .then(() => {
            resolve();
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    RESET: (context) => {
      context.commit("resetState");
    },
  },
};
