<template>
  <v-app>
    <div class="map-print" id="map-print"></div>
    <router-view />
  </v-app>
</template>

<script>
export default {};
</script>
<style>
html,
body {
  overflow: hidden !important;
}

.default-theme.splitpanes .splitpanes .splitpanes__splitter,
.proeminent-pane {
  z-index: 2 !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  font-size: 1rem !important;
}

.map-print {
  width: 594mm;
  height: 370mm; /* 420mm minus margin and header */
  background-color: yellow;
  z-index: -1;
  position: absolute;
}

@import "./assets/styles/variables.css";
</style>
