import locodes from "@/locodes.json";
import store from "@/store/index";
import colormap from "colormap";

const CARGOS = [
  {
    id: 1,
    code: [20, 21, 22, 23, 24, 25, 26, 27, 28, 29],
    color: "#C03638",
    name: "Hidroavião",
  },
  {
    id: 2,
    code: [30],
    color: "#FFC11F",
    name: "Pesca",
  },
  {
    id: 3,
    code: [31, 52],
    color: "#9D6666",
    name: "Rebocador",
  },
  {
    id: 4,
    code: [32, 33],
    color: "#9D7050",
    name: "Dragas",
  },
  {
    id: 5,
    code: [34],
    color: "#9D7050",
    name: "Mergulho",
  },
  {
    id: 6,
    code: [35],
    color: "#F34648",
    name: "Militar",
  },
  {
    id: 7,
    code: [36],
    color: "#66C547",
    name: "Veleiro",
  },
  {
    id: 8,
    code: [37],
    color: "#66C547",
    name: "Recreio",
  },
  {
    id: 9,
    code: [38, 39],
    color: "#5EC8BD",
    name: "Reservado",
  },
  {
    id: 10,
    code: [40, 41, 42, 43, 44, 45, 46, 47, 48, 49],
    color: "#C259B5",
    name: "Lancha",
  },
  {
    id: 11,
    code: [50],
    color: "#8C4EB8",
    name: "Piloto",
  },
  {
    id: 12,
    code: [51, 53],
    color: "#F34648",
    name: "Salva-vidas",
  },
  {
    id: 13,
    code: [54],
    color: "#F34648",
    name: "Proteção ambiental",
  },
  {
    id: 14,
    code: [55],
    color: "#F34648",
    name: "Forças de segurança",
  },
  {
    id: 15,
    code: [56, 57],
    color: "#A8A8A8",
    name: "Substituição",
  },
  {
    id: 16,
    code: [58],
    color: "#F34648",
    name: "Transporte médico",
  },
  {
    id: 17,
    code: [60, 61, 62, 63, 64, 65, 66, 67, 68, 69],
    color: "#FF8A22",
    name: "Passageiro",
  },
  {
    id: 18,
    code: [70, 71, 72, 73, 74, 75, 76, 77, 78, 79],
    color: "#3875D7",
    name: "Mercadorias",
  },
  {
    id: 19,
    code: [80, 81, 82, 83, 84, 85, 86, 87, 88, 89],
    color: "#444444",
    name: "Petroleiro",
  },
  {
    id: 20,
    code: [0, 59, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99],
    color: "#A8A8A8",
    name: "Outro",
  },
];

const STATUSES = [
  {
    code: "EXPECTED_ARRIVAL_VESSEL_CALLS",
    text: "Chegada Prevista",
    color: "#5470c6",
  },
  {
    code: "BERTHED_VESSEL_CALLS",
    text: "Atracado",
    color: "#43A047",
  },
  {
    code: "ANCHORED_VESSEL_CALLS",
    text: "Fundeado",
    color: "#FF9800",
  },
  {
    code: "HOVERING_VESSEL_CALLS",
    text: "A pairar",
    color: "#80D8FF",
  },
  {
    code: "IN_MANOEUVRE_VESSEL_CALLS",
    text: "Em manobras",
    color: "#ee6666",
  },
  {
    code: "EXITED_VESSEL_CALLS",
    text: "A sair",
    color: "#607D8B",
  },
];

const ERROR_TYPPES = [
  {
    code: "NO_AIS_SIGNAL",
    text: "Sem AIS ativo",
    icon: "mdi-radar",
    type: "info",
    color: "#2196f3",
  },
  {
    code: "LOCATION_INVALID",
    text: "Posição AIS diferente",
    icon: "mdi-map-marker-distance",
    type: "warning",
    color: "#fb8c00",
  },
  {
    code: "NO_MOORING_BITT",
    text: "Sem cabeços",
    icon: "mdi-window-shutter-open",
    type: "info",
    color: "#2196f3",
  },
  {
    code: "NO_VALID_ETD",
    text: "ETD inválido",
    icon: "mdi-calendar-alert",
    type: "error",
    color: "#C90303",
  },
  {
    code: "ETD_EXPIRED",
    text: "ETD expirado",
    icon: "mdi-calendar-alert",
    type: "warning",
    color: "#fb8c00",
  },
  {
    code: "INVALID_BOARD_TYPE",
    text: "Bordo de atracação errado",
    icon: "mdi-arrow-left-right-bold",
    type: "error",
    color: "#C90303",
  },
  {
    code: "ERROR",
    text: "Escala com erros",
    icon: "mdi-alert",
    type: "error",
    color: "#C90303",
  },
];

export default {
  getMeterProjection() {
    return store.state.user.account.locode &&
      store.state.user.account.locode.meter_projection
      ? store.state.user.account.locode.meter_projection
      : `+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs`;
  },

  getCargos() {
    return CARGOS;
  },

  getVesselCallStatuses() {
    return STATUSES;
  },

  getAdminUserType() {
    return "ROOT";
  },

  getManagerUserType() {
    return "USER";
  },

  getLocode() {
    return store.state.user.account.locode;
  },

  getErrorTypes() {
    return ERROR_TYPPES;
  },

  getLocale() {
    return store.state.user.account.locode
      ? store.state.user.account.locode.locale
      : "pt-PT";
  },

  getTimezone() {
    return store.state.user.account.locode
      ? store.state.user.account.locode.timezone
      : "Europe/London";
  },

  getLocodes() {
    let vue_app_locodes = window.VUE_APP_LOCODES
      ? window.VUE_APP_LOCODES.split(",")
      : [];
    if (vue_app_locodes.length > 0) {
      return locodes.filter((locode) => {
        return vue_app_locodes.indexOf(locode.code) !== -1;
      });
    } else return locodes;
  },

  getUrlRaster() {
    let url =
      "https://{s}.basemaps.cartocdn.com/rastertiles/light_nolabels/{z}/{x}/{y}.png";

    if (window.VUE_APP_DEFAULT_URL_RASTER)
      url = window.VUE_APP_DEFAULT_URL_RASTER;

    return url;
  },

  getDefaultRasterCode() {
    return "baselayer";
  },
  getDefaultRasterUrl() {
    return "https://{s}.basemaps.cartocdn.com/rastertiles/light_nolabels/{z}/{x}/{y}.png";
  },
  getColoredRasterCode() {
    return "baselayer_nv";
  },
  getColoredRasterUrl() {
    return "https://{s}.basemaps.cartocdn.com/rastertiles/voyager_nolabels/{z}/{x}/{y}.png";
  },

  getRasterUrlByCode(code) {
    return code === "baselayer_nv"
      ? this.getColoredRasterUrl()
      : this.getDefaultRasterUrl();
  },

  getApiUrl() {
    return process.env.VUE_APP_API_URL;
  },

  getRealtimeUrl() {
    return process.env.VUE_APP_REALTIME_URL;
  },

  getColors() {
    return colormap({
      colormap: "velocity-blue",
      nshades: 50,
      format: "rgbaString",
      alpha: 1,
    }).reverse();
  },

  getSurveySymbol() {
    return {
      lineColor: "#34495e",
      lineWidth: 0,
      polygonFill: "rgb(231,209, 92)",
      polygonOpacity: 0.6,
      textFaceName: "Nunito",
      textName: "{z}", //value from name in geometry's properties
      textStyle: "normal", //'italic', 'oblique'
      textSize: {
        stops: [
          [18, 0],
          [19, 10],
        ],
      },
      textOpacity: 1,
      textLineSpacing: 0,
      textDx: 0,
      textDy: 0,
      textHorizontalAlignment: "middle", //left | middle | right | auto
      textVerticalAlignment: "middle", // top | middle | bottom | auto
      textAlign: "center", //left | right | center | auto,
      textFill: "#34495e",
    };
  },

  getShipType(code) {
    for (let i = 0; i < CARGOS.length; i++) {
      var shipClassification = CARGOS[i];
      if (shipClassification) {
        var j = 0;
        for (j = 0; j < shipClassification.code.length; j++) {
          var shipClassifCode = shipClassification.code[j];
          if (shipClassifCode == code) {
            return shipClassification;
          }
        }
      }
    }

    return CARGOS[CARGOS.length - 1];
  },
};
