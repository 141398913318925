import { vesselCallsService } from "@/api_services/vessel_calls.service";
import Vue from "vue";

let initialState = {
  all: [],
  selected: null,
};

const state = Vue.util.extend({}, initialState);

export default {
  namespaced: true,
  state: state,
  mutations: {
    resetState(state) {
      for (let f in state) {
        Vue.set(state, f, initialState[f]);
      }
    },

    setAll(state, items) {
      state.all = items;
    },

    setSelected(state, selected) {
      state.selected = selected;
    },

    updateVesselCalls(state, recordActions) {
      let vcalls = JSON.parse(JSON.stringify(state.all));
      recordActions.forEach((recordAction) => {
        if (
          recordAction.operation == "add" ||
          recordAction.operation == "update"
        ) {
          let item = recordAction.record;
          let foundIndex = state.all.findIndex((el) => {
            return el.id == item.id;
          });
          if (foundIndex !== -1) {
            vcalls.splice(foundIndex, 1, item);
          } else {
            vcalls.push(item);
          }
        } else if (recordAction.operation == "remove") {
          let item = recordAction.record;
          let foundIndex = vcalls.findIndex((el) => {
            return el.id == item.id;
          });
          if (foundIndex !== null || typeof foundIndex == "undefined") {
            vcalls.splice(foundIndex, 1);
          }
        }
      });
      state.all = vcalls;
    },

    addOrUpdate(state, item) {
      let foundIndex = state.all.findIndex((el) => {
        return el.id == item.id;
      });
      if (foundIndex !== -1) {
        state.all.splice(foundIndex, 1, item);
      } else {
        state.all.push(item);
      }
    },

    remove(state, item) {
      let foundIndex = state.all.findIndex((el) => {
        return el.id == item.id;
      });
      if (foundIndex) {
        state.all.splice(foundIndex, 1);
      }
    },
  },

  actions: {
    SET_ALL(context, records) {
      return new Promise((resolve) => {
        context.commit("setAll", records);
        resolve();
      });
    },

    UPDATE_VESSEL_CALLS(context, recordAction) {
      return new Promise((resolve) => {
        context.commit("updateVesselCalls", recordAction);
        resolve();
      });
    },

    REMOVE(context, record) {
      return new Promise((resolve) => {
        context.commit("remove", record);
        resolve();
      });
    },

    HISTORY(_, filter) {
      return new Promise((resolve, reject) => {
        vesselCallsService
          .getHistory(filter)
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    GET_BERTHING_PLANNER(_, imo) {
      return new Promise((resolve, reject) => {
        vesselCallsService
          .getBerthingPlanner(imo)
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    RESET: (context) => {
      context.commit("resetState");
    },
  },
};
