import configs from "@/helpers/configs";
import axios from "axios";
import { sharedHelpers } from "@/api_services/utils/shared-helpers";

export const userService = {
  login,
  logout,
  isLoggedIn,
  forgotPassword,
  resetPassword,
};

function isLoggedIn() {
  if (typeof localStorage !== "undefined") {
    return !!sharedHelpers.getStorageItemWithExpiry("account");
  }
  return false;
}

async function login(credential, password, locode) {
  var user = {
    credential,
    password,
    locode,
  };

  var requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return await axios
    .post(configs.getApiUrl() + "/auth/login", user, requestOptions)
    .catch(() => {
      return null;
    });
}

async function forgotPassword(email) {
  var requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return await axios
    .post(configs.getApiUrl() + "/auth/forgot-password", email, requestOptions)
    .catch(() => {
      return null;
    });
}

async function resetPassword(email, password, token) {
  var user = {
    email,
    password,
    token,
  };

  var requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return await axios
    .post(configs.getApiUrl() + "/auth/reset-password", user, requestOptions)
    .catch(() => {
      return null;
    });
}

async function logout() {
  localStorage.removeItem("account");

  var requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return await axios.post(
    configs.getApiUrl() + "/auth/logout",
    {},
    requestOptions
  );
}
