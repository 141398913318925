import { plansService } from "@/api_services/plans.service";
import Vue from "vue";

let initialState = {
  plansList: [],
  versionsList: [],
  loading: false,
  annotations: [],
  annotationsEnabled: false,
  //vars options
  totalNumberOfPlans: 0,
  searchedTerm: "",
  currentPage: 1,
  itemsPerPage: 10,
  sortBy: "start_date",
  sortDesc: true,
  //to send api
  payload: {
    search: "",
    currentPage: 1,
    itemsPerPage: 10,
    sortBy: "start_date",
    sortDesc: true,
  },
};

const state = Vue.util.extend({}, initialState);

export default {
  namespaced: true,
  state: state,
  mutations: {
    resetState(state) {
      for (let f in state) {
        Vue.set(state, f, initialState[f]);
      }
    },

    setPlans(state, plans) {
      state.plansList = plans;
    },
    setTotalNumberOfPlans(state, total) {
      state.totalNumberOfPlans = total;
    },
    setVersions(state, versions) {
      state.versionsList = versions;
    },
    setLoading(state, isLoading) {
      state.loading = isLoading;
    },
    setAnnotations(state, annotations) {
      state.annotations = annotations;
    },
    setAnnotationsEnabled(state, annotationsEnabled) {
      state.annotationsEnabled = annotationsEnabled;
    },
    setItemsPerPage(state, itemsPerPage) {
      state.itemsPerPage = itemsPerPage;
    },
    setCurrentPage(state, currentPage) {
      state.currentPage = currentPage;
    },
    setSearchedTerm(state, searchedTerm) {
      state.searchedTerm = searchedTerm;
    },
    setSortBy(state, sortBy) {
      state.sortBy = sortBy;
    },
    setSortDesc(state, sortDesc) {
      state.sortDesc = sortDesc;
    },
    setPayload(state, payload) {
      state.payload = payload;
    },
    setAll(state, all) {
      state.itemsPerPage = all.itemsPerPage;
      state.currentPage = all.currentPage;
      state.searchedTerm = all.searchedTerm;
      state.sortBy = all.sortBy;
      state.sortDesc = all.sortDesc;
    },
  },
  actions: {
    SET_ITEMS_PER_PAGE(context, itemsPerPage) {
      return new Promise((resolve) => {
        context.commit("setItemsPerPage", itemsPerPage);
        resolve();
      });
    },
    SET_CURRENT_PAGE(context, currentPage) {
      return new Promise((resolve) => {
        context.commit("setCurrentPage", currentPage);
        resolve();
      });
    },
    SET_SEARCHED_TERM(context, searchedTerm) {
      return new Promise((resolve) => {
        context.commit("setSearchedTerm", searchedTerm);
        resolve();
      });
    },
    SET_SORT_BY(context, sortBy) {
      return new Promise((resolve) => {
        context.commit("setSortBy", sortBy);
        resolve();
      });
    },
    SET_SORT_DESC(context, sortDesc) {
      return new Promise((resolve) => {
        context.commit("setSortDesc", sortDesc);
        resolve();
      });
    },
    SET_PLANS(context, records) {
      return new Promise((resolve) => {
        context.commit("setPlans", records);
        resolve();
      });
    },

    SET_TOTAL_NUMBER_OF_PLANS(context, total) {
      return new Promise((resolve) => {
        context.commit("setTotalNumberOfPlans", total);
        resolve();
      });
    },

    SET_PAYLOAD(context, payload) {
      return new Promise((resolve) => {
        context.commit("setPayload", payload);
        resolve();
      });
    },

    SET_ANNOTATIONS(context, annotations) {
      return new Promise((resolve) => {
        context.commit("setAnnotations", annotations);
        resolve();
      });
    },

    SET_ANNOTATIONS_ENABLED(context, annotationsEnabled) {
      return new Promise((resolve) => {
        context.commit("setAnnotationsEnabled", annotationsEnabled);
        resolve();
      });
    },

    GET_PLANS: (context) => {
      return new Promise((resolve, reject) => {
        plansService
          .getPlans(context.state.payload)
          .then((res) => {
            context.commit("setPlans", res.data.data);
            context.commit("setTotalNumberOfPlans", res.data.total);
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    CREATE_PLAN: (context, plan) => {
      return new Promise((resolve, reject) => {
        plansService
          .createPlan(plan)
          .then((res) => {
            resolve(res.data);
            context.dispatch("GET_PLANS");
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    UPDATE_PLAN: (context, plan) => {
      return new Promise((resolve, reject) => {
        plansService
          .updatePlan(plan)
          .then((res) => {
            resolve(res.data);
            context.dispatch("GET_PLANS");
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    ARCHIVE_PLAN: (context, plan) => {
      return new Promise((resolve, reject) => {
        plansService
          .archivePlan(plan)
          .then((res) => {
            resolve(res.data);
            context.dispatch("GET_PLANS");
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    DELETE_PLAN: (context, planId) => {
      return new Promise((resolve, reject) => {
        plansService
          .deletePlan(planId)
          .then((res) => {
            resolve(res.data);
            let payload = {
              search: "",
              currentPage: 1,
              itemsPerPage: 10,
              sortBy: "start_date",
              sortDesc: true,
            };
            context.commit("setPayload", payload);
            context.commit("setAll", payload);
            context.dispatch("GET_PLANS");
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    VALIDATE_PLAN: (context, plan) => {
      return new Promise((resolve, reject) => {
        plansService
          .validatePlan(plan)
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    LOAD_PLAN: (context, id) => {
      return new Promise((resolve, reject) => {
        context.commit("setLoading", true);
        plansService
          .getPlan(id)
          .then((res) => {
            context.commit("setLoading", false);
            resolve(res.data);
          })
          .catch((e) => {
            context.commit("setLoading", false);
            console.error(e);
            reject(e);
          });
      });
    },

    LOAD_VERSION: (context, id) => {
      return new Promise((resolve, reject) => {
        context.commit("setLoading", true);
        plansService
          .getVersion(id)
          .then((res) => {
            context.commit("setLoading", false);
            resolve(res.data);
          })
          .catch((e) => {
            context.commit("setLoading", false);
            console.error(e);
            reject(e);
          });
      });
    },

    GET_VERSIONS: (context, plan_id) => {
      return new Promise((resolve, reject) => {
        plansService
          .getVersions(plan_id)
          .then((res) => {
            context.commit("setVersions", res.data);
            resolve(res.data);
          })
          .catch((e) => {
            context.commit("setVersions", []);
            console.error(e);
            reject(e);
          });
      });
    },

    DELETE_VERSION: (context, payload) => {
      return new Promise((resolve, reject) => {
        plansService
          .deleteVersion(payload.plan_id, payload.version_id)
          .then((res) => {
            context.dispatch("GET_VERSIONS", payload.plan_id);
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    EXPORT_PLAN: (_, payload) => {
      return new Promise((resolve, reject) => {
        plansService
          .exportPlan(payload.plan.id, payload.version.id)
          .then((res) => {
            var fileURL = window.URL.createObjectURL(new Blob([res.data]));
            var fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute(
              "download",
              payload.plan.name + "_v" + payload.version.number + ".xlsx"
            );
            document.body.appendChild(fileLink);

            fileLink.click();

            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    SCREENSHOT(_, id) {
      return new Promise((resolve, reject) => {
        plansService
          .getPlanScreenshot(id)
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    RESET: (context) => {
      context.commit("resetState");
    },
  },
};
