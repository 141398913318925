import configs from "@/helpers/configs";
import axios from "axios";
import { sharedHelpers } from "@/api_services/utils/shared-helpers";

export const shipsService = {
  getShips,
  getCompleteListOfShips,
  getLazyPhoto,
  getCountriesList,
  createShip,
  getShipById,
  updateShip
};

async function getShips(options) {
  let sort = options.sortDesc ? "DESC" : "ASC";
  var requestOptions = {
    method: "GET",
    params: {
      shipsPerPage: options.itemsPerPage,
      currentPage: options.currentPage,
      sortBy: options.sortBy,
      sort: sort,
      search: options.search,
    },
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.get(configs.getApiUrl() + "/v1/ships", requestOptions);
}

async function getCompleteListOfShips(search) {
  var requestOptions = {
    method: "GET",
    params: {
      search: search,
    },
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.get(
    configs.getApiUrl() + "/v1/ships/complete",
    requestOptions
  );
}

async function getCountriesList() {
  var requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.get(
    configs.getApiUrl() + "/v1/public/countries",
    requestOptions
  );
}

async function getShipById(shipId) {
  var requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.get(
    configs.getApiUrl() + "/v1/ships/" + shipId,
    requestOptions
  );
}

function getLazyPhoto() {
  return require("@/assets/placeholder_ship.jpg");
}

async function createShip(ship) {
  let formData = new FormData();

  for (let param in ship) {
    if (ship[param] != null && ship[param] != "")
      formData.append(param, ship[param]);
  }
  var requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.post(
    configs.getApiUrl() + "/v1/ships",
    formData,
    requestOptions
  );
}

async function updateShip(ship) {
  let formData = new FormData();

  for (let param in ship) {
    if (ship[param] != null && ship[param] != "")
      formData.append(param, ship[param]);
  }

  var requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };

  return await axios.post(
    configs.getApiUrl() + "/v1/ships/" + ship.id,
    formData,
    requestOptions
  );
}
