import { shipTypesService } from "@/api_services/shiptypes.service";
import Vue from "vue";

let initialState = {
  shipTypesList: [],
};

const state = Vue.util.extend({}, initialState);

export default {
  namespaced: true,
  state: state,
  getters: {},
  mutations: {
    setShipTypes(state, shipTypes) {
      state.shipTypesList = shipTypes;
    },
    resetState(state) {
      for (let f in state) {
        Vue.set(state, f, initialState[f]);
      }
    },
  },
  actions: {
    GET_SHIP_TYPES: (context) => {
      return new Promise((resolve, reject) => {
        shipTypesService
          .getShipTypes()
          .then((res) => {
            context.commit("setShipTypes", res.data);
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    CREATE_SHIP_TYPE: (context, shipType) => {
      return new Promise((resolve, reject) => {
        shipTypesService
          .createShipType(shipType)
          .then((res) => {
            resolve(res.data);
            context.dispatch("shiptypes/GET_SHIP_TYPES");
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    UPDATE_SHIP_TYPE: (context, shipType) => {
      return new Promise((resolve, reject) => {
        shipTypesService
          .updateShipType(shipType)
          .then((res) => {
            resolve(res.data);
            context.dispatch("shiptypes/GET_SHIP_TYPES");
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    DELETE_SHIP_TYPE: (context, shipTypeId) => {
      return new Promise((resolve, reject) => {
        shipTypesService
          .deleteShipType(shipTypeId)
          .then((res) => {
            resolve(res.data);
            context.dispatch("shiptypes/GET_SHIP_TYPES");
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    RESET: (context) => {
      context.commit("resetState");
    },
  },
};