import configs from "@/helpers/configs";
import axios from "axios";
import { sharedHelpers } from "@/api_services/utils/shared-helpers";

export const shipTypesService = {
  getShipTypes,
  createShipType,
  updateShipType,
  deleteShipType,
};

async function getShipTypes() {
  var requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.get(configs.getApiUrl() + "/v1/ship_types", requestOptions);
}

async function createShipType(shipType) {
  var requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.post(
    configs.getApiUrl() + "/v1/ship_types",
    shipType,
    requestOptions
  );
}

async function updateShipType(shipType) {
  var requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.put(
    configs.getApiUrl() + "/v1/ship_types/" + shipType.id,
    shipType,
    requestOptions
  );
}

async function deleteShipType(shipTypeId) {
  var requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.delete(
    configs.getApiUrl() + "/v1/ship_types/" + shipTypeId,
    requestOptions
  );
}