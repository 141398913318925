import configs from "@/helpers/configs";
import axios from "axios";
import { sharedHelpers } from "@/api_services/utils/shared-helpers";

export const zonesService = {
  getAll,
  getAllPublic,
  importZones,
  updateZone,
  deleteZone,
  exportZones,
};

async function getAll() {
  var requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
  };

  const res = await axios.get(
    configs.getApiUrl() + "/v1/zones",
    requestOptions
  );
  return res;
}

async function getAllPublic(locode) {
  var requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
  };

  if (locode) requestOptions.headers["X-PortLocode"] = locode.code;

  const res = await axios.get(
    configs.getApiUrl() + "/v1/public/zones",
    requestOptions
  );
  return res;
}

async function importZones(payload) {
  let locode = await sharedHelpers.getLocode();
  var requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };
  var bodyFormData = new FormData();
  bodyFormData.append("file", payload.file);
  bodyFormData.append("replace", payload.replace);
  bodyFormData.append("locode", locode.code);
  const res = await axios.post(
    configs.getApiUrl() + "/v1/zones/import",
    bodyFormData,
    requestOptions
  );
  return res;
}

async function updateZone(zone) {
  var requestOptions = {
    method: "PUT",
    headers: sharedHelpers.getRequestHeaders(),
  };
  const res = await axios.put(
    configs.getApiUrl() + "/v1/zones/" + zone.id,
    zone,
    requestOptions
  );
  return res;
}

async function deleteZone(id) {
  var requestOptions = {
    method: "DELETE",
    headers: sharedHelpers.getRequestHeaders(),
  };
  const res = await axios.delete(
    configs.getApiUrl() + "/v1/zones/" + id,
    requestOptions
  );
  return res;
}

async function exportZones() {
  const requestOptions = {
    method: "POST",
    responseType: "blob",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.post(
    configs.getApiUrl() + "/v1/zones/export",
    {},
    requestOptions
  );
}
