import { tidesService } from "@/api_services/tides.service";
import Vue from "vue";

let initialState = {
  predictions: [],
};

const state = Vue.util.extend({}, initialState);

export default {
  namespaced: true,
  state: state,
  getters: {},
  mutations: {
    setPredictions(state, predictions) {
      state.predictions = predictions;
    },
    resetState(state) {
      for (let f in state) {
        Vue.set(state, f, initialState[f]);
      }
    },
  },
  actions: {
    GET_PREDICTIONS: (context, range) => {
      return new Promise((resolve) => {
        tidesService
          .getPredictions(range)
          .then(({ data }) => {
            context.commit("setPredictions", data);
            resolve(data);
          })
          .catch((e) => {
            console.error(e);
          });
      });
    },

    GET_PREDICTION: (context, date) => {
      return new Promise((resolve) => {
        tidesService
          .getPrediction(date)
          .then(({ data }) => {
            resolve(data);
          })
          .catch((e) => {
            console.error(e);
          });
      });
    },

    RESET: (context) => {
      context.commit("resetState");
    },
  },
};
