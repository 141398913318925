import { shipsService } from "@/api_services/ships.service";
import Vue from "vue";

let initialState = {
  all: [],
  selected: null,
};

const state = Vue.util.extend({}, initialState);

export default {
  namespaced: true,
  state: state,
  mutations: {
    resetState(state) {
      for (let f in state) {
        Vue.set(state, f, initialState[f]);
      }
    },

    setSelected(state, selected) {
      state.selected = selected;
    },

    addOrUpdate(state, item) {
      let foundIndex = state.all.findIndex((el) => {
        return el.id == item.id;
      });
      if (foundIndex !== -1) {
        state.all.splice(foundIndex, 1, item);
      } else {
        state.all.push(item);
      }
    },

    remove(state, item) {
      let foundIndex = state.all.findIndex((el) => {
        return el.id == item.id;
      });
      if (foundIndex) {
        state.all.splice(foundIndex, 1);
      }
    },
  },

  actions: {
    ADD_OR_UPDATE(context, record) {
      return new Promise((resolve) => {
        context.commit("addOrUpdate", record);
        resolve();
      });
    },

    REMOVE(context, record) {
      return new Promise((resolve) => {
        context.commit("remove", record);
        resolve();
      });
    },

    GET_AIS_SHIP_PHOTO: (_, shipIMO) => {
      return new Promise((resolve, reject) => {
        shipsService
          .getAisShipPhotoByIMO(shipIMO)
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    RESET: (context) => {
      context.commit("resetState");
    },
  },
};
