import configs from "@/helpers/configs";
import axios from "axios";
import {
  sharedHelpers
} from "@/api_services/utils/shared-helpers";

export const settingsService = {
  getLdapConfigurations,
  saveLdapConfigurations,
};

async function getLdapConfigurations() {
  var requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.get(configs.getApiUrl() + "/v1/settings/ldap", requestOptions);
}

async function saveLdapConfigurations(configurations) {
  var requestOptions = {
    method: "PUT",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.put(configs.getApiUrl() + "/v1/settings/ldap", configurations, requestOptions);
}