import { mooringService } from "@/api_services/mooring.service";
import Vue from "vue";

let initialState = {
  mooringsList: [],
  mooringBittGroupsList: [],
  zhs: [],
  loading: false,
};

const state = Vue.util.extend({}, initialState);

export default {
  namespaced: true,
  state: state,
  getters: {},
  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },
    setMooringList(state, list) {
      state.mooringsList = list;
    },
    setMooringZHList(state, list) {
      state.zhs = list;
    },
    setMooringBittGroupsList(state, list) {
      state.mooringBittGroupsList = list;
    },
    resetState(state) {
      for (let f in state) {
        Vue.set(state, f, initialState[f]);
      }
    },
  },
  actions: {
    GET_ALL_MOORING: (context) => {
      return new Promise((resolve) => {
        context.commit("setLoading", true);
        mooringService
          .getAll()
          .then((res) => {
            context.commit("setMooringList", res.data);
            context.commit("setLoading", false);
            resolve(res.data);
          })
          .catch((e) => {
            context.commit("setLoading", false);
            console.error(e);
          });
      });
    },

    GET_ALL_PUBLIC_MOORING: (context, locode) => {
      return new Promise((resolve) => {
        context.commit("setLoading", true);
        mooringService
          .getAllPublic(locode)
          .then((res) => {
            context.commit("setMooringList", res.data);
            context.commit("setLoading", false);
            resolve(res.data);
          })
          .catch((e) => {
            context.commit("setLoading", false);
            console.error(e);
          });
      });
    },

    GET_ALL_MOORING_ZHS: (context) => {
      return new Promise((resolve) => {
        mooringService
          .getAllZHs()
          .then((res) => {
            context.commit("setMooringZHList", res.data);
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
          });
      });
    },

    GET_ALL_MOORING_BITT_GROUPS: (context) => {
      return new Promise((resolve) => {
        context.commit("setLoading", true);
        mooringService
          .getAllMooringBittGroups()
          .then((res) => {
            context.commit("setMooringBittGroupsList", res.data);
            context.commit("setLoading", false);
            resolve(res.data);
          })
          .catch((e) => {
            context.commit("setLoading", false);
            console.error(e);
          });
      });
    },

    GET_MOORING_BITT: (context, mooringBittId) => {
      return new Promise((resolve, reject) => {
        mooringService
          .getMooringBitt(mooringBittId)
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    CREATE_MOORING_BITT: (context, mooringBitt) => {
      return new Promise((resolve, reject) => {
        mooringService
          .createMooringBitt(mooringBitt)
          .then((res) => {
            resolve(res.data);
            context.dispatch("GET_ALL_MOORING");
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    UPDATE_MOORING_BITT: (context, mooringBitt) => {
      return new Promise((resolve, reject) => {
        mooringService
          .updateMooringBitt(mooringBitt)
          .then((res) => {
            resolve(res.data);
            context.dispatch("GET_ALL_MOORING");
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    DELETE_MOORING_BITT: (context, mooringBittId) => {
      return new Promise((resolve, reject) => {
        mooringService
          .deleteMooringBitt(mooringBittId)
          .then((res) => {
            resolve(res.data);
            context.dispatch("GET_ALL_MOORING");
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    UPDATE_MOORING_BITTS: (context, mooringBitts) => {
      return new Promise((resolve, reject) => {
        mooringService
          .updateMooringBitts(mooringBitts)
          .then((res) => {
            resolve(res.data);
            context.dispatch("GET_ALL_MOORING");
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    CREATE_MOORING_BITT_GROUP: (context, mooringBittGroup) => {
      return new Promise((resolve, reject) => {
        mooringService
          .createMooringBittGroup(mooringBittGroup)
          .then((res) => {
            resolve(res.data);
            context.dispatch("GET_ALL_MOORING_BITT_GROUPS");
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    UPDATE_MOORING_BITT_GROUP: (context, mooringBittGroup) => {
      return new Promise((resolve, reject) => {
        mooringService
          .updateMooringBittGroup(mooringBittGroup)
          .then((res) => {
            resolve(res.data);
            context.dispatch("GET_ALL_MOORING_BITT_GROUPS");
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    DELETE_MOORING_BITT_GROUP: (context, mooringBittGroupId) => {
      return new Promise((resolve, reject) => {
        mooringService
          .deleteMooringBittGroup(mooringBittGroupId)
          .then((res) => {
            resolve(res.data);
            context.dispatch("GET_ALL_MOORING_BITT_GROUPS");
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    RESET: (context) => {
      context.commit("resetState");
    },

    IMPORT_MOORING_BITTS: (context, payload) => {
      return new Promise((resolve, reject) => {
        mooringService
          .importMooringBitts(payload)
          .then((res) => {
            resolve(res.data);
            context.dispatch("GET_ALL_MOORING");
            context.dispatch("GET_ALL_MOORING_BITT_GROUPS");
          })
          .catch((e) => {
            context.dispatch("GET_ALL_MOORING");
            context.dispatch("GET_ALL_MOORING_BITT_GROUPS");
            reject(e);
          });
      });
    },

    EXPORT: (_, locode) => {
      return new Promise((resolve, reject) => {
        mooringService
          .exportMooringBitts()
          .then((res) => {
            var reader = new FileReader();
            reader.onload = function (event) {
              var fileData = event.target.result;
              var fileBlob = new Blob([fileData], { type: "application/json" });
              var fileURL = window.URL.createObjectURL(fileBlob);
              var fileLink = document.createElement("a");

              fileLink.href = fileURL;
              fileLink.setAttribute(
                "download",
                "cabecos_" + locode.code + ".geojson"
              );
              document.body.appendChild(fileLink);

              fileLink.click();

              resolve(res.data);
            };
            reader.readAsText(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
  },
};
