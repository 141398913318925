import configs from "@/helpers/configs";
import axios from "axios";
import { sharedHelpers } from "@/api_services/utils/shared-helpers";

export const tidesService = {
  getPredictions,
  getPrediction,
};

async function getPredictions(range) {
  var requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
  };

  if (range) {
    requestOptions.params = {
      start: range.startDate,
      end: range.endDate,
    };
  }

  const res = await axios.get(
    configs.getApiUrl() + "/v1/tides/predictions",
    requestOptions
  );
  return res;
}

async function getPrediction(date) {
  var requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
  };

  if (date) {
    requestOptions.params = {
      date: date,
    };
  }

  const res = await axios.get(
    configs.getApiUrl() + "/v1/tides/prediction",
    requestOptions
  );
  return res;
}
