import configs from "@/helpers/configs";
import axios from "axios";
import { sharedHelpers } from "@/api_services/utils/shared-helpers";

export const mooringService = {
  getAll,
  getAllPublic,
  getAllMooringBittGroups,
  getMooringBitt,
  updateMooringBitt,
  createMooringBitt,
  deleteMooringBitt,
  updateMooringBitts,

  createMooringBittGroup,
  updateMooringBittGroup,
  deleteMooringBittGroup,

  importMooringBitts,
  exportMooringBitts,
  getAllZHs,
};

async function getAll() {
  var requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
  };

  const res = await axios.get(
    configs.getApiUrl() + "/v1/mooring_bitts",
    requestOptions
  );
  return res;
}

async function getAllPublic(locode) {
  var requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
  };

  if (locode) requestOptions.headers["X-PortLocode"] = locode.code;

  const res = await axios.get(
    configs.getApiUrl() + "/v1/public/mooring_bitts",
    requestOptions
  );
  return res;
}

async function getAllZHs() {
  var requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
  };
  const res = await axios.get(
    configs.getApiUrl() + "/v1/mooring_bitts/zh",
    requestOptions
  );
  return res;
}

async function getAllMooringBittGroups() {
  var requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
  };
  const res = await axios.get(
    configs.getApiUrl() + "/v1/mooring_bitt_groups",
    requestOptions
  );
  return res;
}

async function getMooringBitt(mooringBittId) {
  var requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
  };
  const res = await axios.get(
    configs.getApiUrl() + "/v1/mooring_bitts/" + mooringBittId,
    requestOptions
  );
  return res;
}

async function updateMooringBitt(mooringBitt) {
  var requestOptions = {
    method: "PUT",
    headers: sharedHelpers.getRequestHeaders(),
  };
  const res = await axios.put(
    configs.getApiUrl() + "/v1/mooring_bitts/" + mooringBitt.id,
    mooringBitt,
    requestOptions
  );
  return res;
}

async function createMooringBitt(mooringBitt) {
  var requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };
  const res = await axios.post(
    configs.getApiUrl() + "/v1/mooring_bitts",
    mooringBitt,
    requestOptions
  );
  return res;
}

async function deleteMooringBitt(mooringBittId) {
  var requestOptions = {
    method: "DELETE",
    headers: sharedHelpers.getRequestHeaders(),
  };
  const res = await axios.delete(
    configs.getApiUrl() + "/v1/mooring_bitts/" + mooringBittId,
    requestOptions
  );
  return res;
}

async function updateMooringBitts(mooringBitts) {
  var requestOptions = {
    method: "PUT",
    headers: sharedHelpers.getRequestHeaders(),
  };
  const res = await axios.put(
    configs.getApiUrl() + "/v1/mooring_bitts",
    { mooring_bitts: mooringBitts },
    requestOptions
  );
  return res;
}

async function createMooringBittGroup(mooringBittGroup) {
  var requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };
  const res = await axios.post(
    configs.getApiUrl() + "/v1/mooring_bitt_groups",
    mooringBittGroup,
    requestOptions
  );
  return res;
}

async function updateMooringBittGroup(mooringBittGroup) {
  var requestOptions = {
    method: "PUT",
    headers: sharedHelpers.getRequestHeaders(),
  };
  const res = await axios.put(
    configs.getApiUrl() + "/v1/mooring_bitt_groups/" + mooringBittGroup.id,
    mooringBittGroup,
    requestOptions
  );
  return res;
}

async function deleteMooringBittGroup(mooringBittGroupId) {
  var requestOptions = {
    method: "DELETE",
    headers: sharedHelpers.getRequestHeaders(),
  };
  const res = await axios.delete(
    configs.getApiUrl() + "/v1/mooring_bitt_groups/" + mooringBittGroupId,
    requestOptions
  );
  return res;
}

async function importMooringBitts(payload) {
  const requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };
  var bodyFormData = new FormData();
  bodyFormData.append("file", payload.file);
  bodyFormData.append("replace", payload.replace);
  return await axios.post(
    configs.getApiUrl() + "/v1/mooring_bitts/import",
    bodyFormData,
    requestOptions
  );
}

async function exportMooringBitts() {
  const requestOptions = {
    method: "POST",
    responseType: "blob",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.post(
    configs.getApiUrl() + "/v1/mooring_bitts/export",
    {},
    requestOptions
  );
}
