import Vue from "vue";
import Vuex from "vuex";

import user from "./modules/user";
import users from "./modules/users";
import vessel_calls from "./modules/vessel_calls";
import moorings from "./modules/moorings";
import layers from "./modules/layers";
import zones from "./modules/zones";
import plans from "./modules/plans";
import settings from "./modules/settings";
import shiptypes from "./modules/shiptypes";
import surveys from "./modules/surveys";
import tides from "./modules/tides";
import ais_ships from "./modules/ais_ships";
import ships from "./modules/ships";
import notifications from "./modules/notifications";
import screenshots from "./modules/screenshots";
import audit_logs from "./modules/audit_logs";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    users,
    vessel_calls,
    moorings,
    layers,
    zones,
    plans,
    settings,
    shiptypes,
    surveys,
    tides,
    ais_ships,
    ships,
    notifications,
    screenshots,
    audit_logs,
  },
  actions: {
    CLEAR_ALL(context) {
      // Clear existing namespaced stores
      context.dispatch("vessel_calls/RESET", {}, { root: true });
      context.dispatch("moorings/RESET", {}, { root: true });
      context.dispatch("layers/RESET", {}, { root: true });
      context.dispatch("zones/RESET", {}, { root: true });
      context.dispatch("plans/RESET", {}, { root: true });
      context.dispatch("settings/RESET", {}, { root: true });
      context.dispatch("shiptypes/RESET", {}, { root: true });
      context.dispatch("surveys/RESET", {}, { root: true });
      context.dispatch("tides/RESET", {}, { root: true });
      context.dispatch("ais_ships/RESET", {}, { root: true });
      context.dispatch("ships/RESET", {}, { root: true });
      context.dispatch("notifications/RESET", {}, { root: true });
      context.dispatch("users/RESET", {}, { root: true });
      context.dispatch("screenshots/RESET", {}, { root: true });
      context.dispatch("audit_logs/RESET", {}, { root: true });
    },
  },
});
