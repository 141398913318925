import { screenshotsService } from "@/api_services/screenshots.service";
import Vue from "vue";

let initialState = {
  loading: false,
  vesselCalls: [],
  layers: [],
  observations: null,
  annotations: [],
  portLocode: null,
  title: null,
  date: null,
  baseLayer: "baselayer",
  properties: null,
};

const state = Vue.util.extend({}, initialState);

export default {
  namespaced: true,
  state: state,
  mutations: {
    resetState(state) {
      for (let f in state) {
        Vue.set(state, f, initialState[f]);
      }
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
    setPortLocode(state, portLocode) {
      state.portLocode = portLocode;
    },
    setAnnotations(state, annotations) {
      state.annotations = annotations;
    },
    setObservations(state, observations) {
      state.observations = observations;
    },
    setVesselCalls(state, vesselCalls) {
      state.vesselCalls = vesselCalls;
    },
    setLayers(state, layers) {
      state.layers = layers;
    },
    setTitle(state, title) {
      state.title = title;
    },
    setDate(state, date) {
      state.date = date;
    },
    setBaseLayer(state, baseLayer) {
      state.baseLayer = baseLayer;
    },
    setProperties(state, properties){
      state.properties = properties;
    },
  },
  actions: {
    SET_ANNOTATIONS: (context, annotations) => {
      context.commit("setAnnotations", annotations);
    },

    SET_VESSEL_CALLS: (context, vesselCalls) => {
      context.commit("setVesselCalls", vesselCalls);
    },

    SET_LAYERS: (context, layers) => {
      context.commit("setLayers", layers);
    },

    SET_BASE_LAYER: (context, baseLayer) => {
      context.commit("setBaseLayer", baseLayer);
    },

    CREATE_SCREENSHOT: (_, screenshot) => {
      return new Promise((resolve, reject) => {
        screenshotsService
          .createScreenshot(screenshot)
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    UPDATE_SCREENSHOT: (_, screenshot) => {
      return new Promise((resolve, reject) => {
        screenshotsService
          .updateScreenshot(screenshot)
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    GET_SCREENSHOT(context, code) {
      return new Promise((resolve, reject) => {
        screenshotsService
          .getScreenshotByCode(code)
          .then((res) => {
            if (!res.data.error) {
              context.commit("setAnnotations", res.data.annotations);
              context.commit("setObservations", res.data.observations);
              context.commit("setVesselCalls", res.data.vessel_calls);
              context.commit("setLayers", res.data.layers);
              context.commit("setPortLocode", res.data.port_locode);
              context.commit("setTitle", res.data.title);
              context.commit("setDate", res.data.date);
              context.commit("setBaseLayer", res.data.base_layer);
              context.commit("setProperties", res.data.properties);
            }
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    RESET: (context) => {
      context.commit("resetState");
    },
  },
};
