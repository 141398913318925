import configs from "@/helpers/configs";
import axios from "axios";
import {
  sharedHelpers
} from "@/api_services/utils/shared-helpers";

export const vesselCallsService = {
  getHistory,
  getBerthingPlanner,
};

async function getHistory(filter) {
  var requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.post(configs.getApiUrl() + "/v1/vessel_calls/history", filter, requestOptions);
}

async function getBerthingPlanner(imo) {
  var requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };

  return await axios.post(configs.getApiUrl() + "/v1/vessel_calls/berthing_planner", { imo: imo }, requestOptions);
}