import configs from "@/helpers/configs";
import axios from "axios";
import { sharedHelpers } from "@/api_services/utils/shared-helpers";

export const layersService = {
  getLayers,
  createLayer,
  updateLayer,
  deleteLayer,
};

async function getLayers() {
  var requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders()
  };
  return await axios.get(configs.getApiUrl() + "/v1/layers", requestOptions);
}

async function createLayer(layer) {
  var requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.post(
    configs.getApiUrl() + "/v1/layers",
    layer,
    requestOptions
  );
}

async function updateLayer(layer) {
  var requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.put(
    configs.getApiUrl() + "/v1/layers/" + layer.id,
    layer,
    requestOptions
  );
}

async function deleteLayer(layerId) {
  var requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.delete(
    configs.getApiUrl() + "/v1/layers/" + layerId,
    requestOptions
  );
}
